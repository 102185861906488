export const TranslationMap = {
	'web.woltadvisor.components': {
		'1F': 'web.woltadvisor.components.1F',
		'3F': 'web.woltadvisor.components.3F',
		FVEmax: 'web.woltadvisor.components.FVEmax',
		FVEmin: 'web.woltadvisor.components.FVEmin',
		HYBRID: 'web.woltadvisor.components.HYBRID',
		Kapacita: 'web.woltadvisor.components.Kapacita',
		ON_GRID: 'web.woltadvisor.components.ON_GRID',
		PVBatteryCapacity: 'web.woltadvisor.components.PVBatteryCapacity',
		PV_GUARANTEE_STANDARD: 'web.woltadvisor.components.PV_GUARANTEE_STANDARD',
		PV_INVERTER_TYPE: 'web.woltadvisor.components.PV_INVERTER_TYPE',
		PV_NUMBER_OF_PHASES: 'web.woltadvisor.components.PV_NUMBER_OF_PHASES',
		Performance: 'web.woltadvisor.components.Performance',
		Rám: 'web.woltadvisor.components.Rám',
		SKIP_RESERVATION: 'web.woltadvisor.components.SKIP_RESERVATION',
		guarantee_standard: 'web.woltadvisor.components.guarantee_standard',
		litres: 'web.woltadvisor.components.litres',
		optimumPlan: 'web.woltadvisor.components.optimumPlan',
		piece: 'web.woltadvisor.components.piece',
		pv_guarantee_cycles: 'web.woltadvisor.components.pv_guarantee_cycles',
		pv_guarantee_mechanical: 'web.woltadvisor.components.pv_guarantee_mechanical',
		pv_guarantee_production: 'web.woltadvisor.components.pv_guarantee_production',
		true: 'web.woltadvisor.components.true',
		Účinnost: 'web.woltadvisor.components.Účinnost',
		černý: 'web.woltadvisor.components.černý'
	},
	'web.marketplace.country': {
		CN: 'web.marketplace.country.CN',
		DE: 'web.marketplace.country.DE',
		JP: 'web.marketplace.country.JP',
		KR: 'web.marketplace.country.KR',
		SE: 'web.marketplace.country.SE',
		US: 'web.marketplace.country.US',
		AT: 'web.marketplace.country.AT',
		AU: 'web.marketplace.country.AU',
		BE: 'web.marketplace.country.BE',
		BG: 'web.marketplace.country.BG',
		BR: 'web.marketplace.country.BR',
		CA: 'web.marketplace.country.CA',
		CH: 'web.marketplace.country.CH',
		CZ: 'web.marketplace.country.CZ',
		DK: 'web.marketplace.country.DK',
		EE: 'web.marketplace.country.EE',
		ES: 'web.marketplace.country.ES',
		FI: 'web.marketplace.country.FI',
		FR: 'web.marketplace.country.FR',
		GB: 'web.marketplace.country.GB',
		GR: 'web.marketplace.country.GR',
		HR: 'web.marketplace.country.HR',
		HU: 'web.marketplace.country.HU',
		ID: 'web.marketplace.country.ID',
		IE: 'web.marketplace.country.IE',
		IL: 'web.marketplace.country.IL',
		IN: 'web.marketplace.country.IN',
		IT: 'web.marketplace.country.IT',
		LT: 'web.marketplace.country.LT',
		LU: 'web.marketplace.country.LU',
		LV: 'web.marketplace.country.LV',
		MX: 'web.marketplace.country.MX',
		MY: 'web.marketplace.country.MY',
		NL: 'web.marketplace.country.NL',
		NO: 'web.marketplace.country.NO',
		NZ: 'web.marketplace.country.NZ',
		PL: 'web.marketplace.country.PL',
		PT: 'web.marketplace.country.PT',
		RO: 'web.marketplace.country.RO',
		RU: 'web.marketplace.country.RU',
		SG: 'web.marketplace.country.SG',
		SI: 'web.marketplace.country.SI',
		SK: 'web.marketplace.country.SK',
		TH: 'web.marketplace.country.TH',
		TR: 'web.marketplace.country.TR',
		UA: 'web.marketplace.country.UA',
		VN: 'web.marketplace.country.VN',
		ZA: 'web.marketplace.country.ZA'
	}
}

type TranslationMapType = typeof TranslationMap
type ComponentKeys = keyof TranslationMapType
export type TranslationKeys = keyof TranslationMapType[ComponentKeys]
